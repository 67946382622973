import { PageProps, graphql } from 'gatsby'
import Articles from '../../components/Articles/Articles'
import Seo from '../../components/seo'
import content from '../../content/blog/blog'
import BlogLayout from '../../components/BlogLayout/BlogLayout'
import { getAuthorObjectList } from '../../utils/author_helper'
import { ArticleCardProps } from '../../components/Articles/ArticleCard/ArticleCard'

const BlogHomepage = ({
  data,
  pageContext,
}: PageProps<Queries.AllBlogPostsQuery>) => {
  const authorMap = pageContext['authorMap']
  const articles: ArticleCardProps[] = data?.allMarkdownRemark.nodes.map(
    (post) => {
      return {
        slug: post.fields?.slug || '',
        authors: getAuthorObjectList(authorMap[post.fields?.slug] || []),
        date: post.frontmatter?.date || '',
        title: post.frontmatter?.title || '',
        category: post.frontmatter?.category || '',
        description: post.frontmatter?.description || '',
        thumbnailImage: post.frontmatter?.thumbnailImage || null,
      }
    }
  )

  return (
    <BlogLayout>
      <Articles articles={articles} />
    </BlogLayout>
  )
}

export default BlogHomepage

const { meta_title, meta_description, meta_image } = content
export const Head = () => (
  <Seo title={meta_title} description={meta_description} image={meta_image} />
)

export const pageQuery = graphql`
  query AllBlogPosts {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          category
          description
          thumbnailImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
